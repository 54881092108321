/*Embed Responsive Classes*/
.cr-embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.cr-embed-responsive:before {
    display: block;
    content: '';
}

.cr-embed-responsive .cr-embed-responsive-item,
.cr-embed-responsive iframe,
.cr-embed-responsive embed,
.cr-embed-responsive object,
.cr-embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.cr-embed-responsive-21by9:before {
    padding-top: 42.85714286%;
}

.cr-embed-responsive-16by9:before {
    padding-top: 56.25%;
}

.cr-embed-responsive-4by3:before {
    padding-top: 75%;
}

.cr-embed-responsive-1by1:before {
    padding-top: 100%;
}

@media screen and (max-width: 499px) {
    .cr-embed-responsive-21by9:before,
    .cr-embed-responsive-16by9:before {
        padding-top: 100%;
    }
}
