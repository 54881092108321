/* .guvMEY {
  z-index: 1400 !important;
} */

.app {
  max-height: auto;
  width: 100%;
  padding: 10px !important;
  background-color: #fff;
  border: 1px solid #e8e8e8 !important;
  text-align: center;
}

.heading {
  text-align: center;
  font-size: 1.3rem;
  font-weight: 500;
  color: #000;
  padding-top: 1.3rem;
}

#file[type='file'] {
  display: none;
}

.label-holder {
  width: 100%;
  height: 50px;
  margin-top: 3em;
  display: grid;
  place-items: center;
}

.label {
  width: 120px;
  height: 100px;
  background-color: white;
  color: #000;
  display: grid;
  place-items: center;
  cursor: pointer;
  border: 1px dashed #c4c4c4;
  border-radius: 7px;
}

.paintpart-label {
  width: 120px;
  height: 100px;
  background-color: white;
  color: #000;
  display: grid;
  place-items: center;
  cursor: pointer;
  border: 1px dashed #c4c4c4;
  border-radius: 7px;
}

.result {
  max-height: auto;
  background-color: #fff;
  /* margin-top: 1rem; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
}

#addcar-img {
  width: 120px;
  height: 100px;
  border-radius: 7px;
  object-fit: cover;
  padding: 2px;
  /* margin: 2px; */
  /* border: 1px solid #c4c4c4; */
}

#paintpart-img {
  width: 120px;
  height: 100px;
  border-radius: 7px;
  object-fit: cover;
  padding: 2px;
}

.error-validation {
  color: red;
  -webkit-margin-start: 5px;
}

.list {
  user-select: none;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 16px;
}

.sortable-list {
  display: grid;
  grid-template-columns: repeat(8, 140px);
  justify-content: start;
  /* column-gap: 10px; */
  row-gap: 10px;
}

@media only screen and (max-width: 1700px) {
  .sortable-list {
    grid-template-columns: repeat(7, 140px);
  }
}

@media only screen and (max-width: 1400px) {
  .sortable-list {
    grid-template-columns: repeat(6, 140px);
  }
}

@media only screen and (max-width: 1200px) {
  .sortable-list {
    grid-template-columns: repeat(7, 140px);
  }

  .label {
    width: 100% !important;
  }
}
@media only screen and (max-width: 900px) {
  .price-gap {
    display: none;
  }
}
@media only screen and (max-width: 612px) {
  .submit-btn {
    width: 100%;
  }

  .sortable-list {
    grid-template-columns: repeat(5, 140px);
  }

  .label {
    width: 100% !important;
  }
}

@media only screen and (max-width: 530px) {
  .sortable-list {
    grid-template-columns: repeat(1, minmax(min-content, 150px));
  }

  .label {
    width: 100% !important;
  }
}

@media only screen and (max-width: 430px) {
  .sortable-list {
    grid-template-columns: repeat(1, minmax(min-content, 120px));
  }

  .label {
    width: 100% !important;
  }
}
