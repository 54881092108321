.app-loader {
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  position: fixed; /* Change from absolute to fixed for viewport coverage */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.2);
}

.loader-spin {
  text-align: center;
  opacity: 1;
}

.crema-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 30px;
  height: 30px;
  opacity: 1; /* Ensure dots remain fully opaque */
}

.crema-dot-spin {
  transform: rotate(45deg);
  animation: hipsterRotate 1.2s infinite linear;
  opacity: 1; /* Ensure spinning remains fully opaque */
}

.crema-dot i {
  width: 9px;
  height: 9px;
  border-radius: 100%;
  background-color: #1890ff;
  transform: scale(0.75);
  display: block;
  position: absolute;
  opacity: 0.5; /* You can control dot opacity separately if needed */
  animation: hipsterSpinMove 1s infinite linear alternate;
  transform-origin: 50% 50%;
}

.crema-dot i:nth-child(1),
.crema-dot i:nth-child(2),
.crema-dot i:nth-child(3),
.crema-dot i:nth-child(4),
.crema-dot i:nth-child(5) {
  opacity: 1; /* Ensure all dots remain fully opaque */
}

@keyframes hipsterSpinMove {
  to {
    opacity: 1;
  }
}

@keyframes hipsterRotate {
  to {
    transform: rotate(405deg);
  }
}
