:root {
  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 600;
  --extra-bold: 700;
  font-family: 'Roboto', serif;
  height: 100%;
}

body {
  line-height: 1.35 !important;
  font-family: 'Roboto', serif;
  height: 100%;
}

html {
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 22px;
  font-weight: 600;
}

h2 {
  font-size: 20px;
  font-weight: 500;
}

h3 {
  font-size: 18px;
  font-weight: 500;
}

h4 {
  font-size: 16px;
  font-weight: 500;
}

h5 {
  font-size: 14px;
  font-weight: 500;
}

h6 {
  font-size: 12px;
  font-weight: 500;
}

p {
  font-size: 14px;
  font-weight: 400;
}

.pointer {
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
.removeArrows input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.removeArrows input[type='number'] {
  -moz-appearance: textfield;
}

.locked-card {
  opacity: 0.5;
  position: relative;
}

.lock-container {
  position: absolute;
  top: -2px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 45px;
  background-color: rgba(204, 204, 204, 0.7);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lock-icon {
  position: absolute;
  top: 5px;
  width: 24px;
  height: 24px;
  background-image: url('../../public/assets/images/Lock.svg');
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 1;
}
